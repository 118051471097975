export default {
  LIST_REPORT_CLASSTIFY: 'ReportClassification/reportClassifications',
  DELETE_REPORT_CLASSTIFY: 'ReportClassification/delete-report-classifications',
  CREATE_REPORT_CLASSTIFY: 'ReportClassification/reportClassifications',
  EDIT_REPORT_CLASSTIFY: 'ReportClassification/reportClassifications',
  DETAIL_REPORT_CLASSTIFY: 'ReportClassification/reportClassifications/',
  COMBOBOX_REPORT_TYPE: 'ReportClassification/get-combobox-report-type',
  LIST_REPORTCLASSFICATION_BUSINESS: 'ReportClassification/list-report-classification-bussiness',
  ADD_REPORTCLASSFICATION_BUSINESS: 'ReportClassification/add-report-classification-bussiness',
  ADD_REPORTCLASSFICATION_ALL_BUSINESS: 'ReportClassification/all-bussiness-report-classification-bussiness',
  DELETE_REPORTCLASSFICATION_BUSINESS: 'ReportClassification/delete-report-classification-bussiness',
  LIST_BUSINESS_NOT_REGISTER: 'ReportClassification/list-bussiness-not-register',
}
