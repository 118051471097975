<template>
  <div
    id="classifyItem"
  >

    <div class="page-container mb-2">
      <h3 class="header">
        Bộ lọc
      </h3>
      <b-row>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Cấp độ báo cáo</label>
            <v-select
              :reduce="label => label.reportLevel"
              label="reportLevelString"
              :options="dataFilterLevel || []"
              :placeholder="'Cấp độ báo cáo'"
              @input="filterLevel"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Loại báo cáo</label>
            <v-select
              :reduce="label => label.rType"
              label="rTypeString"
              :options="dataFilterType || []"
              :placeholder="'Loại báo cáo'"
              @input="filterType"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Năm</label>
            <v-select
              :reduce="label => label"
              :options="dataFilterYear || []"
              :placeholder="'Năm'"
              @input="filterYear"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

      </b-row>
    </div>

    <div class="page-container-table">
      <!--phần header-->
      <button-all-header
        :contentBtnAdd="'Thêm phân loại báo cáo'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        :hideImportFile="false"
        :hideExportFile="false"
        :hideDowload="false"
        :hideAdd="roleUser!=='EducationDepartment'"
        @clickAdd="showModalCreate"
        @clickDelete="deleteItems"
        @search="search($event)"
      />

      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataTable || []"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column:Hạn nộp báo cáo -->
          <span v-if="props.column.field === 'dateExpired'">
            <span class="text-nowrap">{{ props.row.dateExpired | formatDateToDDMM }}</span>
          </span>

          <!-- Column:Chức năng -->
          <span v-else-if="props.column.field === 'actionFunction'">
            <feather-icon
              v-if="roleUser!=='EducationDepartment'"
              v-b-tooltip.hover.top="'Thêm tất cả doanh nghiệp'"
              icon="PlusSquareIcon"
              size="18"
              class="text-body"
              @click="showModalAddAllBusiness(props.row.id)"
            />
            <feather-icon
              v-b-tooltip.hover.top="`${roleUser!=='EducationDepartment'?'Thêm doanh nghiệp':'xem doanh nghiệp'}`"
              icon="BookmarkIcon"
              size="18"
              class="text-body ml-50"
              @click="$router.push({name: 'classify-report-add', params: {id: props.row.id}})"
            />
            <feather-icon
              v-if="roleUser!=='EducationDepartment'"
              v-b-tooltip.hover.top="'Chỉnh sửa'"
              icon="EditIcon"
              size="18"
              class="text-body ml-50"
              @click="showModalEdit(props.row.id)"
            />
            <feather-icon
              v-if="roleUser!=='EducationDepartment'"
              v-b-tooltip.hover.top="'Xóa'"
              icon="Trash2Icon"
              size="18"
              class="text-body ml-50"
              @click="deleteItem(props.row.id)"
            />
          </span>
        </template>
      </vue-good-table>

      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="handlePageClick"
      />

      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />

      <!-- Mở modal thêm tất cả doanh nghiệp vào báo cáo -->
      <confirm-modal
        :id="modalAddAllBusiness"
        content="Bạn có muốn thêm tất cả không?"
        title="Bạn chắc chắn muốn thêm"
        @accept="handleAddAll"
      />

      <!-- Cập nhật loại báo cáo -->
      <modal-edit-report
        :id="modalEditReport"
        :dataDetailReport="dataDetailReport"
        :type="modalType"
        @handleCreateReport="handleCreateReport"
      />
    </div>
  </div>
</template>

<script>
import { formatDateToDDMM } from '@core/utils/filter'
// import { mapActions, mapGetters } from 'vuex'
import {
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  VBTooltip,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalEditReport from './components/ModalEditReport.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
import DataFilter from '../constants/DataFilter'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    ButtonAllHeader,
    ModalEditReport,
    ConfirmModal,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      roleUser: JSON.parse(localStorage.getItem('userData')).roleIdentity,
      confirmModalId: 'confirm-modal',
      columns: [
        {
          label: 'TÊN BÁO CÁO',
          field: 'name',
          sortable: false,
        },
        {
          label: 'CẤP ĐỘ BÁO CÁO',
          field: 'reportLevelString',
          sortable: false,
        },
        {
          label: 'LOẠI BÁO CÁO',
          field: 'rTypeString',
          sortable: false,
        },
        {
          label: 'HẠN BÁO CÁO',
          field: 'dateExpired',
          sortable: false,
        },
        {
          label: 'NĂM',
          field: 'year',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'actionFunction',
          sortable: false,
        },
      ],
      currentPage: 0,
      modalContent: '',
      showBtnMultiDelete: false,
      arrayExcel: null,
      totalRecord: 0,
      urlQuery: {
        reportLevel: '',
        rType: '',
        year: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      dataTable: [],
      dataFilterYear: [],
      dataFilterLevel: DataFilter.dataFilterLevel,
      dataFilterType: DataFilter.dataFilterType,
      modalType: '',
      modalEditReport: 'confirm-modal-report',
      modalAddAllBusiness: 'confirm-all-business',
      reportId: '',
      reportAllId: '',
      dataDetailReport: {},
    }
  },
  computed: {
    ...mapGetters('fiterYear', ['year', 'yearList']),
  },

  created() {
    this.getYear()
    this.dataFilterYear = this.yearList
    this.fetchReportClasstify(this.urlQuery)
  },
  methods: {
    ...mapActions('fiterYear', ['getYear']),
    async fetchReportClasstify(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_REPORT_CLASSTIFY, {
        params: urlQuery,
      })

      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Lọc theo cấp độ báo cáo
    filterLevel(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.reportLevel = val
        this.fetchReportClasstify(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.reportLevel = ''
        this.fetchReportClasstify(this.urlQuery)
      }
    },

    filterType(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.rType = val
        this.fetchReportClasstify(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.rType = ''
        this.fetchReportClasstify(this.urlQuery)
      }
    },

    filterYear(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.year = val
        this.fetchReportClasstify(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.year = ''
        this.fetchReportClasstify(this.urlQuery)
      }
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchReportClasstify(this.urlQuery)
    },

    // lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchReportClasstify(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchReportClasstify(this.urlQuery)
      }
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Thực hiện delete
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_REPORT_CLASSTIFY, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchReportClasstify(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Click hiển thị modal thêm tất cả doanh nghiệp vào báo cáo
    showModalAddAllBusiness(id) {
      this.reportAllId = id
      this.$bvModal.show(this.modalAddAllBusiness)
    },

    // Xử lý thêm tất cả doanh nghiệp
    async handleAddAll() {
      await axiosApiInstance.post(ConstantsApi.ADD_REPORTCLASSFICATION_ALL_BUSINESS, { reportClassificationId: this.reportAllId }).then(response => {
        if (response.status === 200) {
          this.$hideAllPageLoading()
          this.$bvToast.toast('Thêm thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalAddAllBusiness)
        }
      }).catch(err => {
        this.$hideAllPageLoading()
        this.$bvToast.toast(err.response.data.errors[1], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // click show modal thêm
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalEditReport)
    },
    // click show modal sửa
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.reportId = id
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_REPORT_CLASSTIFY}${this.reportId}`)
      this.dataDetailReport = data
      this.$bvModal.show(this.modalEditReport)
    },
    async handleCreateReport(val) {
      const payload = {
        ...val,
        year: parseInt(val.year, 10),
      }
      if (this.modalType === 'edit') {
        this.$showAllPageLoading()
        await axiosApiInstance.put(ConstantsApi.EDIT_REPORT_CLASSTIFY, { ...payload, id: this.reportId }).then(response => {
          if (response.status === 200) {
            this.$hideAllPageLoading()
            this.$bvToast.toast('Sửa thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalEditReport)
            this.fetchReportClasstify(this.urlQuery)
          }
        }).catch(err => {
          this.$hideAllPageLoading()
          this.$bvToast.toast(err.response.data.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }

      if (this.modalType === 'add') {
        await axiosApiInstance.post(ConstantsApi.CREATE_REPORT_CLASSTIFY, payload).then(response => {
          if (response.status === 200) {
            this.$bvToast.toast('Thêm mới thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalEditReport)
            this.fetchReportClasstify(this.urlQuery)
          }
        }).catch(err => {
          this.$bvToast.toast(err.response.data.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }
    },

    // Mở modal cập nhật báo cáo
    showModalEditReport() {
      this.$bvModal.show(this.modalEditReport)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#classifyItem {
  .edit-report {
    cursor: pointer;
  }

  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
